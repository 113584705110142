import { gql } from '@apollo/client';
import { asString } from '@ticketmaster/tm1pos-web-shared/utils/graphQL/graphql-utils';

export const EVENT_DETAILS_QUERY = asString(gql`
  query getEventDetails($eventId: IdentifierInput!, $feeEquivalenceClassId: IdentifierInput) {
    event(eventId: $eventId) {
      id
      eventCode
      hostName
      name
      description
      location {
        city
        state
        countryCode
        venue {
          name
          mapId
        }
      }
      rotatingEntryTokenEnabled
      date
    }
    ticketTypes(eventId: $eventId, feeEquivalenceClassId: $feeEquivalenceClassId) {
      id
      name
      promoCodes
      inventoryOfferSetId
      inventoryExclusive
      minPrice {
        amount
        currency
      }
      maxPrice {
        amount
        currency
      }
      rates {
        id
        priceLevels
        description
        price {
          amount
          currency
        }
      }
    }
    adaTypes(eventId: $eventId) {
      id
      name
      description
    }
    priceLevelGroups(eventId: $eventId) {
      id
      priceLevelIds
    }
    eventFees(eventId: $eventId) {
      id
      description
      price {
        amount
        currency
      }
      maxQuantity
    }
    sellClasses(eventId: $eventId) {
      id
      name
    }
  }
`);
