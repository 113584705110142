import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition } from '@apollo/client/utilities';
import { v4 as uuidV4 } from 'uuid';
import { POS_GRAPHQL_URL, UI_VERSION } from '../../constants';
import { selectFeatureFlags } from '../../feature-flags';
import { selectAuthToken } from '../../store/selectors/user-selector';
import { getAccessToken } from '../../utils/oauth-utils';
export const getHeaders = (store) => ({
    'Content-Type': 'application/json',
    'Feature-Flags': JSON.stringify(selectFeatureFlags(store.getState())),
    'TMPS-Client-App-Version': UI_VERSION,
    'TMPS-Correlation-Id': uuidV4(),
    'access-token': getAccessToken(),
    'tm1pos-authtoken': selectAuthToken(store.getState()) || '',
});
const authLink = (store) => setContext(() => ({
    headers: {
        ...getHeaders(store),
    },
}));
const httpLink = new HttpLink({
    uri: POS_GRAPHQL_URL,
});
const retryLink = new RetryLink({
    attempts: {
        max: 2,
        retryIf: (error) => !!error.networkError,
    },
    delay: {
        initial: 1000,
        max: 2000,
        jitter: true,
    },
});
const linkChain = (store) => split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'query';
}, from([authLink(store), retryLink, httpLink]), from([authLink(store), httpLink]));
export const apolloClient = (store) => new ApolloClient({
    cache: new InMemoryCache(),
    link: from([linkChain(store)]),
});
