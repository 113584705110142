import { useMutation } from '@apollo/client';
import { gql } from '../../../__generated__';
import type { PurchaseMutationVariables } from '../../../__generated__/graphql';

export const PURCHASE_MUTATION = gql(`
mutation purchase($cartId: IdentifierInput!, $customer: CustomerInput!, $payment: PaymentInput!, $note: String!, $linkGlobalAccount: Boolean!) {
  purchase(cartId: $cartId, customer: $customer, payment: $payment, note: $note, linkGlobalAccount: $linkGlobalAccount) {
    __typename
    ... on OrderWithAccountCreated {
      orderId
      accountId
    }
    ... on PurchaseFailed {
      errorCode
    }
  }
}
`);

export const usePurchaseMutation = () => {
  const [purchaseMutation] = useMutation(PURCHASE_MUTATION);

  const purchase = (variables: PurchaseMutationVariables) => purchaseMutation({ variables });

  return { purchase };
};
