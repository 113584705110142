// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Link, Tooltip } from '@ticketmaster/aurora';
import ButtonComponent from '@ticketmaster/tm1pos-web-shared/components/ButtonComponent';
import Card, { ERROR as CardError } from '@ticketmaster/tm1pos-web-shared/components/Card';
import { currencyFormatOptions, SYSTEM_ID } from '@ticketmaster/tm1pos-web-shared/constants';
import {
  selectActivePrinter,
  selectPrinterError,
} from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectHostPermissions } from '@ticketmaster/tm1pos-web-shared/store/selectors/permissions-selector';
import { intlShape } from '@ticketmaster/tm1pos-web-shared/typings/react-intl-types';
import { generateOrderDetailsLink } from '@ticketmaster/tm1pos-web-shared/utils/customer-utils';
import { formatDatesAndTimes } from '@ticketmaster/tm1pos-web-shared/utils/dateUtils';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { selectSuccessOrder } from '../../../App/selectors';
import { ARCHTICS_EVENT_TYPE } from '../../../EventDetailPage/constants';
import { selectCurrentEvent } from '../../../EventDetailPage/selectors/main';
import checkoutMessages from '../../messages';
import { CARD, CASH } from '../Form/constants';
import { DeliveryRow } from './components/delivery-row/delivery-row';
import messages from './messages';

import './styles.scss';

export class OrderConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTip: false,
    };
  }

  getOrderUrl = () => {
    const isArchtics = this.props.currentEvent.type === ARCHTICS_EVENT_TYPE;
    const accountId = isArchtics ? this.props.order.orderId : 0;
    const rawOrderId = isArchtics
      ? String(this.props.order.ticketsData.id).replace(/^ARX-/, '')
      : `${this.props.order.orderId}/${this.props.hostName || ''}`;
    const orderId = `${isArchtics ? SYSTEM_ID.ARCHTICS : SYSTEM_ID.HOST}|${rawOrderId}|${this.props.currentEvent.id}`;
    const marketType = 'PRIMARY';
    return `/customers${generateOrderDetailsLink(accountId, orderId, marketType)}`;
  };

  get paymentsList() {
    return this.props.order.payments.map((payment) => {
      const paymentAmount = payment.amount
        ? this.props.intl.formatNumber(payment.amount.amount, {
            ...currencyFormatOptions,
            currency: payment.amount.currency,
          })
        : '';
      const paymentMethodLabel =
        payment.type === CASH ? this.props.intl.formatMessage(checkoutMessages.checkoutCash) : payment.data;
      let paymentData = '';
      if (this.props.currentEvent.isHost && payment.type === CARD && this.props.isAuthorizationOff) {
        paymentData = <FormattedMessage {...messages.paymentNotProcessed} values={{ paymentData: payment.data }} />;
      } else {
        paymentData = paymentAmount ? `${paymentMethodLabel} / ${paymentAmount}` : paymentMethodLabel;
      }

      return (
        <div className="order-detail__row" key={paymentData}>
          <div className="order-detail__col order-detail__col--left">
            <FormattedMessage {...messages.payment} /> {payment.name}
          </div>
          <div className="order-detail__col order-detail__col--right">{paymentData}</div>
        </div>
      );
    });
  }

  get localPrinterError() {
    if (!this.props.localPrinterError) {
      return null;
    }

    const { title, body, suggestion } = this.props.localPrinterError;
    const titleValues =
      this.props.selectedPrinter && this.props.selectedPrinter.name
        ? Object.assign(title.values || {}, {
            printer: this.props.selectedPrinter.name,
          })
        : title.values;

    const formattedTitleValues = {};
    Object.keys(titleValues).forEach((titleKey) => {
      const message = titleValues[titleKey];
      if (typeof message === 'string') {
        formattedTitleValues[titleKey] = message;
      } else {
        formattedTitleValues[titleKey] = <FormattedMessage {...message} />;
      }
    });

    const bodyValues = Object.assign(body?.values || {}, {
      suggestion: suggestion ? <FormattedMessage {...suggestion} /> : '',
      direction: <FormattedMessage {...messages.directionAfterPrintError} />,
    });
    const header = <FormattedMessage {...title} values={formattedTitleValues} />;
    const childs = body ? <FormattedMessage {...body} values={bodyValues} /> : null;
    return (
      <div className="order-detail__row">
        <Card type={CardError} header={header}>
          {childs}
        </Card>
      </div>
    );
  }

  render() {
    const orderTimeFormatted = formatDatesAndTimes(
      this.props.order.orderTime,
      this.props.order.orderTimeUtcOffset,
      this.props.intl.locale,
    );
    const orderTime = `${orderTimeFormatted.fullDateShortMonth} • ${orderTimeFormatted.time}`;

    return (
      <div className="sdr-order-conf">
        <div className="sdr-order-conf__details">
          <div className="sdr-order-conf__order">
            <div className="order-detail__row">
              <div className="order-detail__col order-detail__col--left">
                <FormattedMessage {...messages.orderId} />
              </div>
              <div className="order-detail__col order-detail__col--right">
                <Link
                  onMouseEnter={() => this.setState({ showTip: true })}
                  onMouseLeave={() => this.setState({ showTip: false })}
                  href={this.getOrderUrl()}
                  weight="semiBold"
                  tabIndex={0}
                >
                  {this.props.order.orderId}
                </Link>
                <Tooltip
                  className="sdr-order-conf__order-tip"
                  direction="bottom"
                  isVisible={this.state.showTip}
                  position={{
                    elTop: 0,
                    elRight: 0,
                  }}
                >
                  <FormattedMessage {...messages.clickToViewOrder} />
                </Tooltip>
              </div>
            </div>
            <div className="order-detail__row">
              <div className="order-detail__col order-detail__col--left">
                <FormattedMessage {...messages.orderTime} />
              </div>
              <div className="order-detail__col order-detail__col--right">{orderTime}</div>
            </div>
            {this.paymentsList}
            <DeliveryRow
              delivery={this.props.order.delivery}
              deliveryMethod={this.props.order.deliveryMethod}
              deliverySuccess={this.props.order.deliverySuccess}
            />
            {this.props.order.customerName ? (
              <div className="order-detail__row">
                <div className="order-detail__col order-detail__col--left">
                  <FormattedMessage {...messages.name} />
                </div>
                <div className="order-detail__col order-detail__col--right">{this.props.order.customerName}</div>
              </div>
            ) : null}
            {this.localPrinterError}
          </div>
        </div>
        <div className="sdr-order-conf__btn">
          <ButtonComponent className="oc-btn" onButtonClick={this.props.continueShopping}>
            <FormattedMessage {...messages.continueSelling} />
          </ButtonComponent>
        </div>
      </div>
    );
  }
}

OrderConfirmation.propTypes = {
  order: PropTypes.object,
  continueShopping: PropTypes.func,
  localPrinterError: PropTypes.object,
  selectedPrinter: PropTypes.object,
  currentEvent: PropTypes.object,
  hostName: PropTypes.string,
  isAuthorizationOff: PropTypes.bool,
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const activePrinter = selectActivePrinter(state);
  const printerError = selectPrinterError(state);
  const order = selectSuccessOrder(state);
  const currentEvent = selectCurrentEvent(state);
  const hostName = selectCurrentEventDetails(state)?.hostName;
  const permissions = selectHostPermissions(state);

  return {
    order,
    localPrinterError: printerError,
    selectedPrinter: activePrinter,
    currentEvent,
    hostName,
    isAuthorizationOff: currentEvent.isHost ? !permissions[hostName].mustAuthorizeCards : undefined,
  };
};
export default connect(mapStateToProps, null)(injectIntl(OrderConfirmation));
