import { selectFeatureFlags } from '@ticketmaster/tm1pos-web-shared/feature-flags';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import { PaymentMethod } from '../../../__generated__/graphql';
import { store } from '../../../store';
import { selectCartId, selectCartTotals } from '../../App/selectors';
import { usePurchaseMutation } from './use-purchase-mutation';
import type { CheckoutForm } from '../types/checkout-form';

// note: temporary code for monitoring, not really part of the final result, standards are loose here :)
const usePurchaseMutationMonitoring = () => {
  const { purchase } = usePurchaseMutation();
  const rawCartId = useSelector(selectCartId);
  const featureFlags = useSelector(selectFeatureFlags);
  const formValues = useSelector(getFormValues('checkout', (state) => state.form)) as any;

  return () => {
    try {
      const state = store.getState();
      if (!featureFlags.monitorPurchase) {
        return;
      }

      const form = formValues.toJSON() as CheckoutForm;
      if (form.paymentMethod !== 'Other') {
        return;
      }

      const cartIdGroups = rawCartId.split('-');
      const cartId = cartIdGroups.at(1);
      if (cartIdGroups.at(0) !== 'ARX' || cartId === undefined) {
        return;
      }

      purchase({
        cartId: {
          id: cartId,
          system: 'ARX',
        },
        customer: {
          name: form.fullName,
          email: form.email,
          phone: {
            number: form.phoneNumber,
            prefix: form.phonePre.name,
          },
        },
        payment: {
          amount: selectCartTotals(state).total.amount,
          method: PaymentMethod.Other,
          other: {
            name: form.otherPaymentMethod.id,
            value: form.paymentInfo,
          },
        },
        note: form.paymentNode ?? '',
        linkGlobalAccount: false,
      }).then();
    } catch (error) {
      console.log(error);
    }
  };
};

// note: only for class component hook support, should be deleted as soon Checkout component is migrate to function component
export const withPurchaseMutationMonitoring = (Component: any) => (props: any) => {
  const triggerModernCheckoutMonitoring = usePurchaseMutationMonitoring();
  return <Component {...props} triggerModernCheckoutMonitoring={triggerModernCheckoutMonitoring} />;
};
