import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { ReverseTransactionStatus } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-types';
import { selectUserInformation } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { useSelector } from 'react-redux';
import ReverseTransactionResult from './reverse-transaction-result';

export const ReverseTransactionResultConnected = () => {
  const {
    transaction: { reverseStatus, reverseError, paymentDetails, transactionId },
  } = useSelector(selectEmvPaymentModuleState);

  const user = useSelector(selectUserInformation);

  return (
    <ReverseTransactionResult
      status={reverseStatus === ReverseTransactionStatus.FAILURE ? 'FAILURE' : 'SUCCESS'}
      error={reverseError ?? undefined}
      payment={{
        tm1Username: user?.profile.username,
        transactionId: transactionId ?? undefined,
        last4: paymentDetails.lastFourDigits ?? undefined,
        amount: paymentDetails.authorizedAmount ?? undefined,
      }}
    />
  );
};
