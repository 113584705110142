import { useEffect } from 'react';
import { browserHistory } from 'react-router';
export function useNavigationBlocking() {
    useEffect(() => {
        const ref = window.location.href;
        browserHistory.push(ref);
        window.onpopstate = () => {
            browserHistory.push(ref);
        };
        return () => {
            window.onpopstate = () => {
                browserHistory.replace('/');
                window.onpopstate = null;
                browserHistory.forward();
            };
            if (typeof browserHistory.back === 'function') {
                browserHistory.back();
            }
        };
    }, []);
}
